// ~~~ CONTENTFUL PAGE ~~~
/* eslint-disable */

import React from 'react'
import Helmet from 'react-helmet'
import Layout from 'components/Layout'
import Hero from 'components/Contentful/Hero'
import HowTo from 'components/Contentful/HowTo'
import IntroAndPicture from 'components/Contentful/IntroAndPicture'

const Page = props => {
  return (
    <Layout location={props.location}>
      <Helmet
        title="Erfahrungsbericht von Familie Tokay"
        meta={[
          { name: 'description', content: 'Wärmepumpen Erfahrungsbericht der Familie Tokay ➤ Heizungsersatz ✔️ Umweltfreundliche Luft/Wasser-Wärmepumpe ✔️' },
          { name: 'keywords', content: 'Erfahrungsbericht Wärmepumpe' },
        ]}
      >
        
      </Helmet>
      
      <div
          className='bg__flex--white'
          key='RXkoXQOmiFOmeFXjzRHHY-2FMmPrhc4vZOZ3YesU2Iqy'
          id='familie-tokay-dulliken-so'
        >
          <Hero
            {...{
    "type": "hero",
    "id": "2FMmPrhc4vZOZ3YesU2Iqy",
    "updatedAt": "2022-06-28T08:13:02.974Z",
    "title": "FAMILIE TOKAY, DULLIKEN SO",
    "size": "Groß",
    "teaser": "Die junge Familie Tokay hat frisch ein Eigenheim erworben, in dem eine Ölheizung über 25 Jahre ihren Dienst verrichtet hatte. Parallel zu einer Kernsanierung haben sich die neuen Eigentümer dazu entschlossen, die fossile Heizung durch eine umweltfreundliche innenaufgestellte Luft/Wasser-Wärmepumpe zu ersetzen. Warum die Wahl auf diese Wärmepumpe fiel (und für Familie Tokay selbst ein wenig überraschend kam), erfahren Sie in unserer neuesten Referenz-Story.",
    "image": {
        "description": "Ein Foto vom Haus der Familie Tokay",
        "contentType": "image/png",
        "url": "//images.ctfassets.net/wbm1kpreb3m8/4sZcRQAJTAqNJntZpnyIPj/b947ca4d574dad7f6707d07ddaf6966a/01---Format---Titelbild-Hero-2--Gross.png"
    },
    "links": [
        {
            "type": "link",
            "id": "2HoGCnl720mMqmQ108H1F7",
            "updatedAt": "2023-07-25T06:25:06.308Z",
            "label": "Zur Referenz-Übersicht",
            "url": "https://www.heizungsmacher.ch/referenzen/"
        }
    ],
    "anchorName": "familie-tokay-dulliken-so"
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--grey'
          key='RXkoXQOmiFOmeFXjzRHHY-7uRfZd32QyDgvF8eZZeMNf'
          id=''
        >
          <HowTo
            {...{
    "type": "howTo",
    "id": "7uRfZd32QyDgvF8eZZeMNf",
    "updatedAt": "2022-06-28T08:22:40.876Z",
    "title": "FAMILIE TOKAY, DULLIKEN SO",
    "steps": [
        {
            "type": "howToStep",
            "id": "4pwuYYUxDTx9WoSAOFckYv",
            "updatedAt": "2022-06-28T08:21:34.515Z",
            "title": "Wärmepumpe im Altbau",
            "description": "<p>Wir durften im neuen – und demnächst kernsanierten – Eigenheim der Familie Tokay vorbeischauen und ein persönliches Interview führen. Für den zweifachen Familienvater Yasin Tokay war schon immer klar, dass er die fossile <a href=\"https://www.heizungsmacher.ch/wissen/oelheizung-ersetzen-informationen-foerderungen-regelungen/\">Ölheizung</a> bei der Kernsanierung durch eine moderne und klimafreundliche <a href=\"https://www.heizungsmacher.ch/wissen/wie-hoch-sind-die-kosten-einer-waermepumpe/#der-vergleich-von-verschiedenen-heizvarianten\">Heizvariante</a> ersetzen lassen würde. Eine Wärmepumpe versprach für die Familie genau das Heizsystem darzustellen, das in der Anschaffung die einfachste und im Betrieb die günstigste Lösung sein würde.</p>\n",
            "image": {
                "description": "Seitenansicht vom Haus der Familie Tokay",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/5ebhuByqfMRL0hQozT79kK/94967494bccc088b08667bbcb54a1585/2206---Aussenfassade.png"
            }
        },
        {
            "type": "howToStep",
            "id": "5Nxi8esyDlBHu8keFBhU4y",
            "updatedAt": "2022-06-28T08:21:47.441Z",
            "title": "Aussen- oder innenaufgestellte Wärmepumpe?",
            "description": "<p>Nachdem Yasin Tokay also klar war, dass es eine <a href=\"https://www.heizungsmacher.ch/waermepumpen/\">Wärmepumpe</a> werden sollte, war die nächste Frage: als aussen- oder innenaufgestelle Variante? Obwohl sich ihm die Frage eigentlich gar nicht stellte, weil er bis dato nur von <a href=\"https://www.heizungsmacher.ch/waermepumpen/luft-wasser-aussenaufstellung/\">aussenaufgestellten Wärmepumpen</a> wusste. Erst im Beratungsgespräch mit der Heizungsmacher AG  erfuhr er von den Vorteilen der innenaufgestellten Alternative. Als dann schliesslich die genauen Gegebenheiten vor Ort sowie die Bedürfnisse der jungen Familie ermittelt worden waren, stand für Yasin Tokay und seine Familie fest: Es wird eine <a href=\"https://www.heizungsmacher.ch/waermepumpen/luft-wasser-innenaufstellung/\">innenaufgestellte Wärmepumpe</a>. «Zwar nimmt die Anlage im Keller mehr Platz in Anspruch als die aussenaufgestellte Variante und ist in der Anschaffung auch ein bisschen teurer, dafür habe ich keine Wärmepumpe im Garten stehen», erklärt Yasin Tokay. Und weiter:</p>\n",
            "image": {
                "description": "Zu- und Fortluft innenaufgestellte Wärmepumpe der Familie Tokay",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/1uGovAkFbP3gY8sugXSZXb/5dfee193759795c17f2c8bdb2f4e5a89/2206---Wandausschnitte.png"
            }
        },
        {
            "type": "howToStep",
            "id": "4liERaMcDwiGxpH9hSS5ay",
            "updatedAt": "2022-06-30T08:24:48.965Z",
            "title": "Was macht die Familie Tokay mit dem neu gewonnen Platz?",
            "description": "<p>Durch das Entfernen der alten <a href=\"https://www.heizungsmacher.ch/wissen/oelheizung-ersetzen-informationen-foerderungen-regelungen/\">Ölheizung</a> wurden neue Räume gewonnen. Für den einen Raum hat die junge Familie klare Vorstellungen. Es soll ein geräumiges Badezimmer werden, ein behaglicher Rückzugsort mit viel Platz. Für den anderen Raum lässt sich die Familie noch Zeit, denn die Sanierungen der Räumlichkeiten sind im vollen Gange und es ist noch nichts in Stein gemeisselt.</p>\n",
            "image": {
                "description": "der alte Keller von Familie Tokay",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/3SKte9ITeXkMbEDciyaoVT/c867673d1b5c0948810c0e45a4d7f6c2/2206---Keller-vorher.png"
            }
        },
        {
            "type": "howToStep",
            "id": "5traUxavpEDuG9OtOuUkF4",
            "updatedAt": "2022-06-29T09:14:20.455Z",
            "title": "Ein weiteres Projekt mit der Heizungsmacher AG",
            "description": "<p>Familie Tokay besitzt noch ein weiteres Eigenheim, bei dem der Heizungsersatz noch in den nächsten Monaten erfolgen soll. Weil Yasin Tokay mit der Betreuung durch unseren Projektleiter sowie dem gesamten Projektverlauf sehr zufrieden war, möchte er auch das nächste Projekt mit uns zusammen in Angriff nehmen.</p>\n<p>In dem Sinne bedanken wir uns herzlich bei der Familie Tokay für ihr Vertrauen und wünschen ihnen noch viel Freude mit ihrer neuen Wärmepumpe. Und natürlich auch mit dem neu entstandenen behaglichen Badezimmer!</p>\n",
            "image": {
                "description": "Der Keller mit der innenaufgestellten Wärmepumpe der Familie Tokay",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/7y71RNhYGIZt2z5NeBbUUW/43187bd690040210cf9d8aa7daa8b120/2206---Foto-Keller.png"
            }
        }
    ]
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--white'
          key='RXkoXQOmiFOmeFXjzRHHY-5jl6yjDwGax05p7DpzlEBw'
          id='wuenschen-sie-eine-beratung'
        >
          <IntroAndPicture
            {...{
    "type": "introAndPicture",
    "id": "5jl6yjDwGax05p7DpzlEBw",
    "updatedAt": "2023-08-30T09:41:16.098Z",
    "title": "Wünschen Sie eine Beratung?",
    "anchorName": "wuenschen-sie-eine-beratung",
    "text": "<p>Sie interessieren sich für eine Wärmepumpe und sind noch nicht sicher, ob dies die richtige Wahl ist? Besuchen Sie unseren kostenlosen Heizungs-Check oder lassen Sie sich von unseren <a href=\"https://www.heizungsmacher.ch/ueber-uns/\" title=\"ueber-uns\">Heizexperten</a> beraten.</p>\n",
    "link": {
        "type": "link",
        "id": "7p7eaG5GRI8A1fElOkNxIb",
        "updatedAt": "2022-06-28T08:23:33.929Z",
        "label": "Online Heizungs-Check",
        "url": "https://www.heizungsmacher.ch/konfigurator"
    }
}} location={props.location}
          />
        </div>
    </Layout>
  )
}

export default Page
